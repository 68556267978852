import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const VTPage = () => (
  <Layout>
    <Seo 
      title="Verhaltenstherapie München Haidhausen | Privatpraxis f. Psychotherapie"
      description="Individuell auf Sie abgestimmte Verhaltenstherapie in München. Kognitive Verhaltenstherapie, Schematherapie, Traumatherapie, Achtsamkeit & emotionsfokussierte Therapie."
    />

    <div className="relative">
      <div className="w-full h-96">
          <StaticImage className="w-full h-full" src="../assets/images/verhaltenstherapie-hero.jpg" alt="" />
          <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
            <div className="w-full text-center text-white">
                <h1 className="lg:text-4xl">Verhaltens&shy;therapie</h1>
                {/* <div>Diese Seite befindet sich im derzeit im Aufbau. Bei Fragen wenden Sie sich gerne an mich</div> */}
            </div>
          </div>
      </div>
      </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <p>Jeder Mensch ist in seinen lebensgeschichtlichen Erfahrungen individuell. Um Sie optimal behandeln zu können, möchte ich Sie ausreichend verstehen lernen. Zunächst nehme ich dabei Bezug auf Ihre Biografie sowie Annahmen und Glaubenssätze, die sich im Laufe Ihres Lebens entwickelt haben. Manchmal machen wir Erfahrungen, die bestimmte Verhaltens- oder Denkmuster fördern, die im Hier und Jetzt Schwierigkeiten bereiten. Gleichzeitig hilft mir das Wissen über die Ressourcen meines Gegenübers, diese mit in die Behandlung zu integrieren.</p>
      <p>Je nach Symptomausprägung und Problemaktualisierung liegt der Schwerpunkt in der Verhaltenstherapie auf der positiven Veränderung von Verhaltensmustern, Denkweisen, Gefühlen und Körperempfindungen. Nach dem Prinzip "Hilfe zur Selbsthilfe" unterstütze ich Sie Schritt für Schritt in der Problembewältigung und im Erreichen Ihrer individuellen Ziele, die wir zu Beginn der Therapie festlegen.</p>
      <p>Als Weiterentwicklung der kognitiven Verhaltenstherapie nutze ich auch Techniken aus der Schematherapie. In der Arbeit mit inneren Anteilen, die jeder von uns besitzt, können erlebnisorientiert vor allem Schwierigkeiten im zwischenmenschlichen Bereich bearbeitet werden.</p>
      <p>Emotionsfokussierte sowie achtsamkeitsbasierte Techniken ergänzen mein therapeutisches Angebot, um eine nachhaltige Veränderung und eine langfristige psychische Gesundheit zu erreichen.</p>
      <p>Ein traumatherapeutisches Vorgehen ist indiziert beim Vorliegen vorangegangener traumatischer Erfahrungen, die nach wie vor Leid erzeugen.</p>
      <p>Die Dialektisch-Behaviorale Therapie (DBT), entwickelt von Marsha M. Linehan, kommt in der Behandlung von Borderline-Persönlichkeitsstörungen und von (komplexen) posttraumatischen Belastungsstörungen zum Einsatz. Auch in der Behandlung von Essstörungen hat sich diese Therapieform etabliert.</p>
    </div>
  </Layout>
)

export default VTPage
